
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import FullPageCardLayout from "@/shared/components/Layouts/FullPageCardLayout.vue";
import routePaths from "@/web/router/routePaths";

export default defineComponent({
  props: {},
  setup() {
    const { t } = useI18n();

    return { t, routePaths };
  },
  methods: {},
  components: { FullPageCardLayout },
});
