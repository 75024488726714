import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_FullPageCardLayout = _resolveComponent("FullPageCardLayout")!

  return (_openBlock(), _createBlock(_component_FullPageCardLayout, { class: "account-deleted" }, {
    "custom-body": _withCtx(() => [
      _createVNode(_component_a_typography_title, {
        level: 2,
        class: "mb-5"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("Your account has been deleted")), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_a_typography_paragraph, { class: "mb-6" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t(
            "All your sessions have been terminated, all your inbox messages have been purged. All your wallet where you was authorized with Avatarada have been active"
          )), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: _ctx.routePaths.register
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("Create another account")), 1)
        ]),
        _: 1
      }, 8, ["to"])
    ]),
    _: 1
  }))
}