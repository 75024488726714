import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex header" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!

  return (_openBlock(), _createBlock(_component_a_layout_content, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          src: _ctx.brandLogoSrc,
          class: "logo"
        }, null, 8, _hoisted_2),
        _renderSlot(_ctx.$slots, "header-right")
      ]),
      (_ctx.$slots['custom-body'])
        ? _renderSlot(_ctx.$slots, "custom-body", { key: 0 })
        : (_openBlock(), _createBlock(_component_a_card, {
            key: 1,
            class: "full-page-card-layout"
          }, {
            title: _withCtx(() => [
              (_ctx.title)
                ? (_openBlock(), _createBlock(_component_a_typography_title, {
                    key: 0,
                    level: 2,
                    class: "full-page-card-layout__title"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.title), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _renderSlot(_ctx.$slots, "title"),
              (_ctx.subtitle)
                ? (_openBlock(), _createBlock(_component_a_typography_paragraph, { key: 1 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.subtitle), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _renderSlot(_ctx.$slots, "subtitle")
            ]),
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default")
            ]),
            _: 3
          }))
    ]),
    _: 3
  }))
}